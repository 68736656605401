<template>
  <div class="ADXSettlement-report-container">
    <div>
      <div class="report-item">
        <el-date-picker
          v-model="months"
          placement="bottom-start"
          type="monthrange"
          range-separator="至"
          start-placeholder="开始月份"
          value-format="yyyy-MM"
          @change="changeMonths"
          :clearable="false"
          end-placeholder="结束月份"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <a-select
          class="search_item"
          :allowClear="true"
          placeholder="请选择结算状态"
          v-model="query.sts"
          @change="handleAutoSearch"
        >
          <a-select-option :value="1">待申请</a-select-option>
          <a-select-option :value="2">待上传发票</a-select-option>
          <a-select-option :value="3">审核失败</a-select-option>
          <a-select-option :value="4">结算中</a-select-option>
          <a-select-option :value="5">结算完成</a-select-option>
          <a-select-option :value="6">结算失败</a-select-option>
        </a-select>
      </div>
      <div class="detail-table">
        <a-table
          class="m_table_cust"
          size="middle"
          :pagination="false"
          :rowKey="(record, index) => index"
          :columns="columns"
          :data-source="dataList"
          tableLayout="fixed"
          :loading="isLoading"
          bordered
        >
          <span slot="userName" slot-scope="userName">
            {{ userName }}
          </span>
          <!-- 状态 1:待申请 2: 待上传发票 3: 审核失败 4:结算中 5:结算完成 6:结算失败 -->
          <span slot="sts" slot-scope="sts">
            <a-tag
              :color="
                +sts === 1
                  ? 'orange'
                  : +sts === 2
                    ? 'red'
                    : +sts === 3
                      ? 'green'
                      : +sts === 3
                        ? 'yellow'
                        : +sts === 4
                          ? 'pink'
                          : +sts === 5
                            ? 'blue'
                            : 'purple'
              "
            >
              {{
                +sts === 1
                  ? '待申请'
                  : +sts === 2
                    ? '待上传发票'
                    : +sts === 3
                      ? '审核失败'
                      : +sts === 4
                        ? '结算中'
                        : +sts === 5
                          ? '结算完成'
                          : '结算失败'
              }}
            </a-tag>
          </span>
          <div slot="action" slot-scope="text, record">
            <a-button
              style="margin-left: -9px"
              v-if="+record.sts === 1"
              type="link"
              size="small"
              @click="changeComputeSts(record)"
            >
              申请结算
            </a-button>
            <a-button style="margin-left: -9px" v-else size="small" type="link" @click="exportReport(record)">
              下载结算单
            </a-button>
          </div>
          <div slot="amount" slot-scope="amount, record">
            <a-button size="small" type="link" @click="getDetail(record)">
              <span style="text-decoration: underline">{{ moneyType+ amount }}</span>
            </a-button>
          </div>
        </a-table>
        <a-pagination
          class="pagination"
          v-if="dataList.length > 0"
          show-size-changer
          :current="query.page"
          :total="total"
          @change="handleChangePage"
          :show-total="(total) => `共 ${total} 条`"
          @showSizeChange="onShowSizeChange"
          :pageSize="query.limit"
        />
        <m-empty v-else style="height: 40vh; width: 100%">
          <template #description> 暂无数据 </template>
        </m-empty>
      </div>
    </div>
    <detailPart
      v-if="showDetailVisible"
      :visible="showDetailVisible"
      :detailQbject="detailQbject"
      @modalCancel="showDetailVisible = false"
    />
  </div>
</template>

<script>
import { adxSettleReport, adxSettleUpdateSts } from '@/api/financialManagementA'
import request from '@/utils/request'
import excel from '@/utils/excel'
import { mapState } from 'vuex'
import { numFormat } from '@/utils/dealNumber'
import moment from 'moment'
const detailPart = () => import(/* webpackChunkName: "dialog" */ '../detailPart')
export default {
  name: 'ADXSettlementReport',
  components: { detailPart },
  data () {
    return {
      query: {
        dateStart: '',
        dateEnd: '',
        sts: undefined,
        page: 1,
        limit: 10
      },
      total: 0,
      dataList: [],
      // 详情弹窗数组
      detailQbject: {},
      isLoading: false,
      // 详情弹窗
      showDetailVisible: false,
      months: [],
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > Date.now()
        }
      },
      columns: [
        {
          title: '业务时间',
          dataIndex: 'date',
          width: '150px',
          scopedSlots: {
            customRender: 'date'
          }
        },
        {
          title: '发票客户名称',
          dataIndex: 'invoiceName',
          width: '150px',
          scopedSlots: {
            customRender: 'invoiceName'
          }
        },
        {
          title: '开发者名称',
          dataIndex: 'developerName',
          width: '150px',
          scopedSlots: {
            customRender: 'developerName'
          }
        },
        {
          title: '结算金额',
          dataIndex: 'amount',
          width: '150px',
          scopedSlots: {
            customRender: 'amount'
          }
        },
        {
          title: '结算状态',
          dataIndex: 'sts',
          width: '150px',
          scopedSlots: {
            customRender: 'sts'
          }
        },
        {
          title: '操作',
          width: '150px',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ]
    }
  },
  created () {
    // 初始化时间
    this.initMonth(2, 0)
    this.handleAutoSearch()
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.user.isAdmin,
      contractSts: (state) => state.user.contractSts,
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      moneyType: (state) => +state.user.cur === 1 ? '$' : '¥'
    })
  },
  methods: {
    // 日期初始化
    initMonth (start, end) {
      const startMonth = moment().add(-start, 'M').format('YYYY-MM')
      const endMonth = moment().add(-end, 'M').format('YYYY-MM')
      this.months = [startMonth, endMonth]
      this.query.dateEnd = endMonth
      this.query.dateStart = startMonth
    },
    // 改变日期
    changeMonths (e) {
      this.query.dateStart = e[0]
      this.query.dateEnd = e[1]
      this.handleAutoSearch()
    },
    async changeComputeSts (record) {
      //  合同签署状态   0否1是
      if (this.contractSts === 0) {
        this.$message.warning('请先联系客服签署结算合同')
      } else {
        const resx = await adxSettleUpdateSts({
          id: record.id,
          sts: 2
        })
        if (resx.code === 200) {
          this.$message.success('修改成功')
          this.handleAutoSearch()
        }
      }
    },
    // 导出表格接口函数
    downloadReport (query) {
      return request.post('adxSettle/download', query, {
        responseType: 'blob'
      })
    },
    // 导出报表
    async exportReport (record) {
      if (this.contractSts === 0) {
        this.$message.warning('请先联系客服签署结算合同')
      } else {
        try {
          const res = await this.downloadReport({
            id: record.id
          })
          await excel(res, `北京前呈无限科技有限公司结算账单-${record.date}.pdf`)
        } catch (e) {
          console.log(e, '导出pdf错误信息')
        }
      }
    },
    // 搜索
    handleAutoSearch () {
      this.query = {
        ...this.query,
        page: 1,
        limit: this.query.limit
      }
      this.getDataList()
    },
    // 得到数据
    async getDataList () {
      this.isLoading = true
      const { data = {} } = await adxSettleReport(this.query)
      const { total = 0, items = [] } = data
      this.total = total
      this.dataList = items
      this.dataList.map((item) => {
        item.amount = numFormat(item.amount, 3, '')
      })
      this.isLoading = false
    },
    // 页数
    handleChangePage (page) {
      this.query.page = page
      this.getDataList()
    },
    // 改变limit
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getDataList()
    },
    // 结算详情
    async getDetail (record) {
      this.detailQbject = record
      this.showDetailVisible = true
    }
  }
}
</script>

<style lang="less" scoped>
.ADXSettlement-report-container {
  margin-top: 20px;
  border-radius: 5px;
  padding: 10px 18px;
  background-color: #fff;
  ::v-deep {
    .report-item {
      height: 77px;
      padding: 15px 0 15px 0;
      .el-range-editor .el-range-input {
        margin: 0 0 0 20px;
      }
      .search_item {
        width: 220px;
        display: inline-block;
        margin-left: 10px;
      }
    }
  }
  .detail-table {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .pagination {
      margin: 10px;
    }
  }
}
</style>
